import React, { useEffect } from 'react'
import cx from 'classnames'

import { RenderModules } from 'src/utils/renderModules'

import { ProductHero } from 'src/components/product/hero'
import { ProductSchema } from 'src/components/product/schema'

import { useSetPage } from 'src/context/siteContext'
import { SEO } from 'src/components/SEO'
import { trackPageView } from "src/components/analytics"

export interface CollectionProps {
  pageContext: {
    main: {
      modules: [],
      slug: {
        current: string
      },
      subTitle: string
      colorAssociation?: string
      title: string
    },
    meta: {}
  }
  transitionStatus: string
}

const Collection = ({
  pageContext
}: CollectionProps) => {
  const setPage = useSetPage()
  const { main } = pageContext
  const {
    main: {
      modules,
      slug,
      subTitle,
      title,
    },
    meta,
  } = pageContext
  useEffect(() => {
    setPage(pageContext.main.slug.current)
    trackPageView(title)
  }, [0])
  const url = `collections/${slug.current}`
  return (
    <div className={cx("ac site__main")}>

      <SEO metaInfo={meta} pagePath={url} />
      <div className='bg-black text-white pt-24'> 
      </div>
      <div className={cx('mxa x al ', pageContext.main.slug.current)}>
        {RenderModules(modules, main)}
      </div>
    </div>
  )
}

export default Collection